import React from 'react';
import profile from '../profile.png'

function Navbar(){
    return (
        <nav className="navigation-bar">
            <ul className="navigation-bar__itemlist">
                <li className="navigation-bar_item" > <span>A</span> Home</li>
                <li className="navigation-bar_item" ><span>B</span> About</li>
                <li className="navigation-bar_item" ><span>C</span> Works</li>
                <li className="navigation-bar_item" ><span>D</span> Contact</li>
            </ul>
        </nav>
    )
}

function Footer(){
    return (
        <footer>
            <div className="email">v i n o d k a r a d i y a @ g m a i l . c o m</div>
        </footer>
    )
}

function Header(){
    return (
        <div className="col s12 m10 l8 offset-l2 offset-m1 center-align">
            <header>
                <img src={profile} />
                <div className="text">
                    <div className="title">
                    <span>Hi there,</span>
                    <br/>
                    {`{ I'm Vinod Karadiya }`}
                    </div>
                    <div className="designation"> ---Software Developer--- </div>
                    <h4 className="subheading">Nature Lover :)</h4>
                    <p>
                    An Entitiy with experience in <span>Software Development</span> ,<span>Web Development</span> and <span>Android Applcation Development</span> . I'm passionate about Technology, Art and Philosophy. I love things simple and organized.
                    </p>
                </div>
              
            </header>
        </div>
    )
}

function AboutMe(params) {
    return (
        <div className="col l6 s12">
        <div className="heading">About Me</div><br/>
        <div className="content">
            Tech + Art + Philosophy = Me
            <br/><br/>
            <p>
                I am fueled by my passion for new technologies and art. I love to explore new things and learn from it. Robotics, photography, energy, philosophy and artificial intelligence are a few examples of my exploration. As I grow up, I am willing to gain more knowledge in each of these fields.
            </p>
            <p>
                I dream of becoming a successful tech entreprenuer and help solving real life world issues rather than exaggerating "making the world a better place".
            </p>
            <p>
                Other than learning, I also like listening to music.  chillstep and indie. Painting is my another hobby. I find nature and space inspiring, so most of my paintings are related to these two subjects.
            </p>
            <p>
                Three words that describe me: <span>Chill</span>, <span>Creative</span> & <span>Determined</span>
            </p>

        </div>
    </div>
    )
}

function MySkills(params) {
    return (

        <div className="col l6 s12">
        <div className="heading">My Skills</div><br/>
        <div className="skill-box">
            <li>Python</li>
            <li>JAVA</li>
            <li>JavaScript</li>
            <li>JSX</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>XML</li>
            <li>Markdown</li>
            <li>React</li>
            <li>Vue</li>
            <li>Bootstrap</li>
            <li>Node</li>
            <li>Django</li>
            <li>Flask</li>
            <li>Google Maps API</li>
            <li>Photoshop</li>
            <li>Illustrator</li>
            <li>After Effects</li>
            <li>XD</li>
            <li>Office</li>
            <li>PowerPoint</li>
            <li>Excel</li>
            <li>Github</li>
            <li>Android Studio</li>
            <li>AdSense</li>
            <li>Google Analytics</li>
            <li>Communication</li>
            <li>Management</li>
            <li>Kotlin</li>
            <li>Go</li>
            <li>Firebase</li>
            <li>Angular</li>
        </div>
        <br/>

    </div>

    )
}

function WorkExperience(params) {
    return (
        <div className="col l6 s12">
        <div className="heading"> Work Experience</div> <br/>
        <ul className="work_experience__list">
            <li className="work_experience__list__item">
                <div className="company__name">Buyucoin</div>
                <div className="work__position">React Developer | Aug 2019 - Present</div>
                <div className="compony__info">
                    <ul>
                        <li>Meeting with the development team to discuss user interface ideas and applications.</li>
                        <li>Reviewing application requirements and interface designs.</li>
                        <li>Identifying web-based user interactions. </li>
                        <li>Developing and implementing highly-responsive user interface components using React concepts.</li>
                        {/* <li>Writing application interface codes using JavaScript following React.js workflows.</li> */}
                        {/* <li>Troubleshooting interface software and debugging application codes.</li> */}
                        {/* <li>Developing and implementing front-end architecture to support user interface concepts.</li> */}
                        {/* <li>Monitoring and improving front-end performance.</li> */}
                        {/* <li>Documenting application changes and developing updates.</li>     */}
                    </ul>
                    </div>
            </li>
            <li className="work_experience__list__item">
                <div className="company__name">Buyucoin</div>
                <div className="work__position">Android App Developer | Jan 2019 - Aug 2019</div>
                <div className="compony__info">A software company where I'm currently working with the software engineering team. My work includes software testing, design thinking and fixing bugs.</div>
            </li>
            <li className="work_experience__list__item">
                <div className="company__name">Buyucoin</div>
                <div className="work__position">UI developer | Jan 2018 - Jan 2019</div>
                <div className="compony__info">A software company where I'm currently working with the software engineering team. My work includes software testing, design thinking and fixing bugs.</div>
            </li>
            
            <li className="work_experience__list__item">
                <div className="company__name">Syncrasy Tech</div>
                <div className="work__position">React Developer | Jan 2020 - Present</div>
                <div className="compony__info">
                <ul>
                        <li>Writing application interface codes using JavaScript following React.js workflows.</li>
                        {/* <li>Troubleshooting interface software and debugging application codes.</li> */}
                        <li>Developing and implementing front-end architecture to support user interface concepts.</li>
                        {/* <li>Monitoring and improving front-end performance.</li> */}
                        {/* <li>Documenting application changes and developing updates.</li>     */}
                    </ul></div>
            </li>
           
        </ul>
    </div>
    )
}

function MyProjects(params) {
    return (
        <div className="col s12" style={{padding:"0px"}}>
        <br/>
        <div className="heading"> My Work</div>
        <ul className="work_experience__list">
           
            <li className="work_experience__list__item">
                <div className="company__name"><a href="https://buyucoin.com/">Buyucoin</a></div>
                <div className="work__position">Digital Currency Exchange | Blockchain Wallet</div>
                <div className="compony__info">BuyUcoin is a digital currency exchange and blockchain wallet service provider headquartered in New Delhi, India. It was founded by Shivam Thakral, Devesh Aggrawal, and Atulya Bhatt in 2016 from their college dorms at Harcourt Butler Technical University.</div>
            </li>
            <li className="work_experience__list__item">
                <div className="company__name"><a href="http://twiitter.kradster.com/">Twiitter</a></div>
                <div className="work__position">In Progress... | Present</div>
                <div className="compony__info">Twitter Clone</div>
            </li>
            <li className="work_experience__list__item">
                <div className="company__name"><a href="https://quiz.kradster.com/">Quizzz</a></div>
                <div className="work__position">In Progress... | Present</div>
                <div className="compony__info">An Online platform for rank your skill..</div>
            </li>
           
        </ul>
    </div>
    )
}

function LearningExp(params) {
    return (
        <div className="col l6 s12">
        <div className="heading"> Learning Experience</div> <br/>
        <div className="content">
            I try my best to get out of my comfort zone and continue my exploration. Over the past few years I had some great opportunities to be a tiny part of the following organizations, companies & schools by attending their events or participating in their competitions. like 
            <br/><br/>
            <ul>
                            <li><span>- Google Foo bar Challenge</span></li>
                            <li><span>- Data Structers</span></li>
                            <li><span>- Object Oriented Programming</span></li>
                            <li><span>- Function Programming</span></li>
                            <li><span>- Design Patterns</span></li>
                        </ul>

        </div>
        <MyProjects/>

    </div>
    )
}

function FireWorks(params) {
    return (
        <div class="firework"></div>
    )
}

export default function Home() {
    return (
        <div className="row">
        <FireWorks/>
        <Header/>
        <Footer/>
         
        <div className="col s12 m10 l8 offset-l2 offset-m1">
            <div className="col s12 no-padding">
               <AboutMe/>
               <MySkills/>
            </div>
                
            <div className="col s12 no-padding">
                <LearningExp/> 
                <WorkExperience/>
              
            </div>
       
    </div>    
        
        </div>
    )
}
